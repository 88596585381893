import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../constants'
import { useForm } from '../../../Hooks/useForm'
import { login, selectToken } from '../../../ReduxStore/features/authSlice'
import Input from '../../../Components/Input'
import './styles.scss'
import CustomButton from '../../../Components/CustomButton'
import logo1 from '../../../Assets/Images/atlas.png'
import CustomAlert from '../../../Components/Alert'
import AuthService from '../../../Services/Auth'
import fetchApiError from '../../../Helpers/fetchApiError'
import AnimateHeight from 'react-animate-height'

const AdminLogin = () => {
  const token = useSelector(selectToken)

  const navigate = useNavigate()

  const dispatch = useDispatch()

  useEffect(() => {
    if (token) {
      navigate(ROUTES.admin.index)
    }
  }, [token])

  const { form, onChange } = useForm({
    email: '',
    password: '',
    loading: false,
    error: null,
  })

  const handleChange = (e) => onChange(e.target.name, e.target.value)

  const handleSubmit = async (e) => {
    e?.preventDefault()

    onChange('loading', true)
    onChange('error', null)

    try {
      const res = await AuthService.login(form)

      const data = {
        user: {
          ...res.data.data,
        },
        token: res.data.tokens.access.token,
        refresh_token: res.data.tokens.refresh.token,
      }

      dispatch(login(data))
    } catch (error) {
      console.error('Error', error)

      onChange('error', fetchApiError(error))
    }

    onChange('loading', false)
  }

  return (
    <div className="admin-login-page">
      <form className="admin-login-page__login-form" onSubmit={handleSubmit}>
        <img src={logo1} alt="Logo" />

        <div>
          <p>
            <b>Hello let's get started</b>
          </p>

          <p>Sign in to continue</p>
        </div>

        <AnimateHeight duration={300} height={form.error != null ? 'auto' : 0}>
          <CustomAlert variant="danger" message={form.error} />
        </AnimateHeight>

        <Input
          title=""
          placeholder="Email"
          type="email"
          name="email"
          value={form.email}
          onChange={handleChange}
          required
        />

        <Input
          title=""
          placeholder="Password"
          type="password"
          name="password"
          value={form.password}
          onChange={handleChange}
          required
        />

        <CustomButton loading={form.loading} label="SIGN IN" />
      </form>
    </div>
  )
}

export default AdminLogin
