import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Outlet, useNavigate } from 'react-router-dom'
import './styles.scss'
import {
  solid,
  regular,
  brands,
} from '@fortawesome/fontawesome-svg-core/import.macro'
import logo1 from '../../Assets/Images/atlas.png'
import NavItem from '../NavItem'
import { ROUTES, SERVICES } from '../../constants'
import { useScrollPosition } from '../../Hooks/useScrollPosition'
import { useState } from 'react'
import Overlay from '../Overlays'
import QuoteForm from './QuoteForm'
import CustomButton from '../CustomButton'
import FooterLink from './FooterLink'
import AnimateHeight from 'react-animate-height'

const Layout = () => {
  const navigate = useNavigate()

  const goToAboutUs = () => navigate(ROUTES.about)

  const scrollPosition = useScrollPosition()

  const [isOpen, setIsOpen] = useState(false)
  const toggleOverlay = () => setIsOpen((prev) => !prev)

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const toggleMobileMenuOverlay = () => setIsMobileMenuOpen((prev) => !prev)

  const [isServicesMenuOpen, setIsServicesMenuOpen] = useState(false)
  const toggleServicesMenuOverlay = () => setIsServicesMenuOpen((prev) => !prev)

  const onMenuItemSelected = (route, value) => () => {
    navigate(route, { state: { service: value } })
    setIsServicesMenuOpen(false)
    setIsMobileMenuOpen(false)
  }

  return (
    <div className="layout">
      <header
        className={`layout__menu ${
          scrollPosition > 50 ? 'layout__menu__solid' : undefined
        }`}
      >
        {scrollPosition <= 50 && (
          <div className="layout__menu__socials">
            <div className="layout__menu__socials__info">
              <span>
                <FontAwesomeIcon className="icon" icon={solid('phone')} />{' '}
                +1 (209) 513-2982 
              </span>

              <span>
                <FontAwesomeIcon className="icon" icon={regular('envelope')} />{' '}
                business@theatlaslogistics.com
              </span>

              <span>
                <FontAwesomeIcon
                  className="icon"
                  icon={solid('location-dot')}
                />{' '}
                Global Facility Office
              </span>
            </div>

            <div className="layout__menu__socials__links">
              <div>
                <FontAwesomeIcon icon={brands('facebook-f')} />
              </div>

              <div>
                <FontAwesomeIcon icon={brands('linkedin-in')} />
              </div>

              <div>
                <FontAwesomeIcon icon={brands('twitter')} />
              </div>
            </div>
          </div>
        )}

        <div className="layout__menu__content">
          <img onClick={() => navigate('/')} src={logo1} alt="Logo" />

          <NavItem label="Home" route="/" />

          <NavItem label="About Us" route={ROUTES.about} />

          <NavItem
            label="Services"
            options={[
              'Air Freight',
              'Ocean Freight',
              'Cargo Transportation',
              'Packaging And Storage',
            ]}
            hasDropDown
            route={ROUTES.services}
          />

          <NavItem label="Track Your Package" route={ROUTES.trackIt} />

          <NavItem label="Contact Us" route={ROUTES.contact} />

          <button className="get-quote" onClick={toggleOverlay}>
            <FontAwesomeIcon className="icon" icon={regular('envelope')} /> Get
            A Quote
          </button>
        </div>
      </header>

      <header className="layout__menu-mobile">
        <div className="layout__menu-mobile__top">
          <img onClick={() => navigate('/')} src={logo1} alt="Logo" />

          <div className="hambug" onClick={toggleMobileMenuOverlay}>
            <FontAwesomeIcon icon={solid('bars')} className={'hambug__icon'} />
          </div>
        </div>

        <AnimateHeight duration={300} height={isMobileMenuOpen ? 'auto' : 0}>
          <div className="layout__menu-mobile__container">
            <div
              className="layout__menu-mobile__item"
              onClick={onMenuItemSelected('/')}
            >
              Home
            </div>
            <div
              className="layout__menu-mobile__item"
              onClick={onMenuItemSelected(ROUTES.about)}
            >
              About Us
            </div>
            <div className="layout__menu-mobile__item services">
              <div
                className="services__top"
                onClick={toggleServicesMenuOverlay}
              >
                <span>Services</span>

                <FontAwesomeIcon
                  className="icon"
                  icon={solid('chevron-down')}
                />
              </div>

              <AnimateHeight
                duration={300}
                height={isServicesMenuOpen ? 24 : 0}
              ></AnimateHeight>

              <AnimateHeight
                duration={300}
                height={isServicesMenuOpen ? 'auto' : 0}
              >
                <div className="services__options">
                  {[
                    'Air Freight',
                    'Ocean Freight',
                    'Cargo Transportation',
                    'Packaging And Storage',
                  ].map((option, idx) => {
                    return (
                      <div
                        key={`${option}-${idx}`}
                        className="services__options__item"
                        onClick={onMenuItemSelected(ROUTES.services, option)}
                      >
                        {option}
                      </div>
                    )
                  })}
                </div>
              </AnimateHeight>
            </div>
            <div
              onClick={onMenuItemSelected(ROUTES.trackIt)}
              className="layout__menu-mobile__item"
            >
              Track Your Package
            </div>
            <div
              onClick={onMenuItemSelected(ROUTES.contact)}
              className="layout__menu-mobile__item"
            >
              Contact Us
            </div>
          </div>
        </AnimateHeight>
      </header>

      <div className="layout__content">
        <Outlet />
      </div>

      <footer className="layout__footer">
        <div className="layout__footer__light">
          <div className="footer__section">
            <img onClick={() => navigate('/')} src={logo1} alt="Logo" />

            <p>
              We’re one of the leading independent shipping and logistics
              providers. Trusted since 1933 by some of the world’s leading
              companies...
            </p>

            <CustomButton
              label={'Read More'}
              otherClassName="read-more"
              onClick={goToAboutUs}
            />
          </div>

          <div className="footer__section">
            <h5>OUR SERVICES</h5>

            <FooterLink
              label="Air Freight"
              route={ROUTES.services}
              state={{ service: SERVICES[0] }}
            />

            <FooterLink
              label="Ocean Freight"
              route={ROUTES.services}
              state={{ service: SERVICES[1] }}
            />

            <FooterLink
              label="Cargo Transportation"
              route={ROUTES.services}
              state={{ service: SERVICES[2] }}
            />

            <FooterLink
              label="Packaging and Storage"
              route={ROUTES.services}
              state={{ service: SERVICES[3] }}
            />
          </div>

          <div className="footer__section">
            <h5>USEFUL LINKS</h5>

            <FooterLink label="About Us" route={ROUTES.about} />

            <FooterLink label="Contact Us" route={ROUTES.contact} />

            <FooterLink label="Track Your Shipping" route={ROUTES.trackIt} />

            <FooterLink label="Get A Quote" route={ROUTES.about} />
          </div>

          <div className="footer__section">
            <h5>LOCATION</h5>

            <div className="location">
              <FontAwesomeIcon className="icon" icon={solid('location-dot')} />

              <div>
                <span>Location:</span>

                <span>GLOBAL FACILITY OFFICE</span>
              </div>
            </div>

            <div style={{ height: 64 }}></div>

            <h5>CALL CENTER</h5>

            <div className="call-center">
              <FontAwesomeIcon className="icon" icon={solid('phone')} />

              <div>
                <span>Call Us:</span>

                <span>+1 (209) 513-2982</span>
              </div>
            </div>
          </div>
        </div>

        <div className="layout__footer__darker">
          <div className="copy-right-text">
            © 2023 Atlas Logistic - All rights reserved
          </div>

          <ul className="social-media-list">
            <FontAwesomeIcon icon={brands('facebook-f')} />

            <FontAwesomeIcon icon={brands('linkedin-in')} />

            <FontAwesomeIcon icon={brands('twitter')} />
          </ul>
        </div>
      </footer>

      <Overlay isOpen={isOpen} onClose={toggleOverlay}>
        <QuoteForm showForm={isOpen} handleClose={() => setIsOpen(false)} />
      </Overlay>
    </div>
  )
}

export default Layout
